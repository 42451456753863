.profileDetailsMain {
    display: flex;
    /* justify-content: space-between; */
    gap: 6rem;
    align-items: center;
    /* padding: 40px; */
    
    background: #FFFFFF;
    border: 0.5px solid #B9C1CE;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
}
.customerReviewMain{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px;
    background: #FFFFFF;
    border: 0.5px solid #B9C1CE;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
}
.allReviewMain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #FFFFFF;
    border: 0.5px solid #B9C1CE;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
}