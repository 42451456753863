.containerResponsive{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 5em;
}

.BabyCareShowImg{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.businessDetails{
    width: 40%;
}

.customerReviewWidth{
    width: 35%;
}

.reviewsWidth{
    width: 60%;
}

.reviewSection{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

@media screen and (max-width: 900px) {
    .reviewSection {
        display: flex !important;
        flex-direction: column !important;
        
    }
}
@media screen and (max-width: 900px){
    .customerReviewWidth{
        width: 100%;
    }

    .reviewsWidth{
        width: 100%;
    }
}
@media screen and (max-width: 741px) {

    .containerResponsive{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .BabyCareShowImg{
        width: 100%;
    }

    .businessDetails{
            width: 100%;
    }

}

